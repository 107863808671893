import React from 'react';
import { AuthProvider } from './context/Auth';
import Routes from './routes1';

export default function App() {
   return (
        <AuthProvider>
            <Routes/>   
        </AuthProvider>
   );
}