import React from "react";
import PageMain from "../../components/page-main";


const Sells = () =>{
    return (
        <PageMain>
            <div>
                <h5>Vendas</h5>
            </div>
        </PageMain>
    )
}

export default Sells;