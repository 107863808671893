import React, { useContext, useEffect } from 'react';
import AuthContext from '../context/Auth';
import AppRoutes from './app.routes';
import AuthRoutes from './auth.routes';

const Routes = () => {
    const { signed, user, checkLogin } = useContext(AuthContext);  
    useEffect(()=>{
        checkToken()
    },[])

    const checkToken = async()=>{
        await checkLogin();
        console.log(user);
    }
    // return <AppRoutes />
    return signed ? <AppRoutes /> : <AuthRoutes />
};

export default Routes;