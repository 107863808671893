import React from "react";
import { FaRegChartBar } from "react-icons/fa";
import { MdGroups, MdAttachMoney } from "react-icons/md";
import { PiShoppingCartDuotone, PiTagFill, PiGear } from "react-icons/pi";

import "./style.css"

const SideMenu = () => {
    return(
        <div style={{display: "flex", width: '100%'}}>
            {/* <div> */}
                <ul style={{flexDirection: 'column', listStyle: "none"}} className="box">
                    <li>
                        <a href="/home">
                            <div>
                                <FaRegChartBar style={{width: 32, height: 32}}/>
                            </div>
                            <div>
                                <p>
                                    Dashboard
                                </p>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="/clients">
                            <div>
                                <MdGroups style={{width: 32, height: 32}}/>
                            </div>
                            <div>
                                <p>
                                    Clientes
                                </p>
                            </div>
                        </a>
                    </li>
                    <li>
                     <a href="/sells">
                            <div>
                                <MdAttachMoney style={{width: 32, height: 32}}/>
                            </div>
                            <div>
                                <p>
                                    Vendas
                                </p>
                            </div>
                        </a>
                    </li>
                    <li>
                      <a href="/abandonedcarts">
                            <div>
                                <PiShoppingCartDuotone style={{width: 32, height: 32}}/>
                            </div>
                            <div>
                                <p>
                                    Carrinhos Abandonados
                                </p>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="/products">
                            <div>
                                <PiTagFill style={{width: 32, height: 32}}/>
                            </div>
                            <div>
                                <p>
                                    Produtos
                                </p>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="/integrations">
                            <div>
                                <PiGear style={{width: 32, height: 32}}/>
                            </div>
                            <div>
                                <p>
                                    Integracoes
                                </p>
                            </div>
                        </a>
                    </li>
                </ul>
            {/* </div> */}
        </div>
    )
}

export default SideMenu;