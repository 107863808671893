import React, {useContext, useEffect} from "react";
import PageMain from "../../components/page-main";
import AuthContext from "../../context/Auth";


const NotFound = () =>{
    // useEffect()
    const{signIn, user, checkLogin} = useContext(AuthContext);
    useEffect(()=>{
        // checkLogin()
    },[])
    
    console.log(user)

    return (
        <PageMain>
            <h5>NAO ENCONTRADO</h5>
        </PageMain>
    )
}

export default NotFound;