import React from "react";
import PageMain from "../../components/page-main";


const Integrations = () =>{
    return (
        <PageMain>
            <div>
                <h5>Integracoes</h5>
            </div>
        </PageMain>
    )
}

export default Integrations;