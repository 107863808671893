import React, {createContext, useState} from "react";
import aws from "../services/aws";
import bcrypt from 'bcryptjs';

const AuthContext = createContext({signed: Boolean});

export const AuthProvider = ({ children }) => {

    const[user, setUser] = useState(null);
    const[darkMode, setDarkMode] = useState(false);

    async function signIn(dataLogin){   
        if(dataLogin.email === "" || dataLogin.password === ""){
            return alert('Favor preencher todos os campos para prosseguir!')
        }
        
        const authenticate = await aws.getUserById(dataLogin.email);
        const hashedPassword = bcrypt.hashSync(dataLogin.password, '$2a$10$CwTycUXWue0Thq9StjUM0u');

        if(authenticate && authenticate.Items[0] && authenticate.Items[0].password && authenticate.Items[0].password.S === hashedPassword){
            const data = {
                name: authenticate.Items[0].name.S,
                email: authenticate.Items[0].email.S
            }    
            localStorage.setItem("userData",JSON.stringify(data));
            return setUser(data)    
        }else if((authenticate.Items.length === 0) || (authenticate && authenticate.Items[0] && authenticate.Items[0].password && authenticate.Items[0].password.S !== hashedPassword)){
            return alert("Senha ou usuarios incorretos")
        }
    }

    async function modeSetting(){
        setDarkMode(!darkMode)
    }

    async function checkLogin(){
        // localStorage.removeItem('userData');
        const userDataCheck = localStorage.getItem("userData");
        if(userDataCheck){return setUser("userData", JSON.stringify(userDataCheck))}
    }

    return(
        <AuthContext.Provider value={{
            signed: !!user, 
            user, 
            signIn, 
            darkMode,
            modeSetting,
            checkLogin
        }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;