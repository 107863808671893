import React, {useContext} from "react";
import { Route, BrowserRouter, Redirect } from "react-router-dom";
import AuthContext from "../context/Auth";

import Login from "../pages/Login";

const AppRoutes = () => {
    const {signed, user} = useContext(AuthContext);
    return(
       <BrowserRouter>
            <Route component = { Login }  path="/" exact/>
       </BrowserRouter>
    )
}

export default AppRoutes;