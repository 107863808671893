import React, {useContext, useEffect} from "react";
import PageMain from "../../components/page-main";
import AuthContext from "../../context/Auth";


const Home = () =>{
    // useEffect()
    const{signIn, user, checkLogin} = useContext(AuthContext);
    useEffect(()=>{
        // checkLogin()
    },[])
    
    console.log(user)

    return (
        <PageMain>
            <div>
                <h5>Dashboard</h5>
            </div>
            <div>
                <div>
                    <h5>Head1</h5>
                </div>
                <div>
                    <h5>Head2</h5>
                </div>
                <div>
                    <h5>Head3</h5>
                </div>
            </div>
            <div>
                <h5>Grafico</h5>
            </div>
        </PageMain>
    )
}

export default Home;