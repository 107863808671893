import React, {useRef} from "react";
import AuthContext from "../../context/Auth";
import { useContext } from "react";
import "./style.css"

const Login = () =>{
    const{signIn} = useContext(AuthContext);

    const emailInputRef = useRef();
    const passwordInputRef = useRef();

    const cmdLogin = async()=>{
        const email = emailInputRef.current.value;
        const password = passwordInputRef.current.value;

        const dataLogin ={
            email: email,
            password: password
        }
            
        await signIn(dataLogin)  
        window.location = '/home';        
    }

    return (
        <div className="main-login">
            <div className="container-login">
                <div>
                    <input placeholder="Email" ref={emailInputRef} type="email"/>
                </div>
                <div>
                    <input placeholder="Senha" ref={passwordInputRef} type='password'/>
                </div>
                <div>
                    <button onClick={()=>cmdLogin() } type="submit">Entrar</button>
                </div>
            </div>
        </div>
    )
}

export default Login;