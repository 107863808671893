import React from "react";
import PageHeader from "../../components/page-header";

const Settings = () =>{

    return (
      <div>
        <PageHeader/>
        <div>
          <h5>Tela Config</h5>
        </div>
      </div>
    );
  }
  
  export default Settings;