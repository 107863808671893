import React, { Children } from "react";
import SideMenu from "../side-menu";
import PageHeader from "../page-header";

const PageMain = ({children}) => {
    return(
        <div style={{display: "flex", width: '100%', background: '#E8F4FD', height: '100%'}}>
            <div style={{display: 'flex'}}>
                <SideMenu />
            </div>
            <div style={{display: "flex", width: '100%', flexDirection: "column", margin: 0, right:0,}}>
                <div>
                    <PageHeader />
                </div>
                <div style={{padding: 16}}>
                    {children}
                </div>
            </div>
        </div>
    )
}

export default PageMain;