import React from "react";
import { Route, BrowserRouter, Switch} from "react-router-dom";

import Home from "../pages/Home";
import Sells from "../pages/Sells";
import Clients from "../pages/Clients";
import Products from "../pages/Produtcts";
import AbandonadedCarts from "../pages/AbandonedCarts";
import Integrations from "../pages/Integrations";
import Settings from "../pages/Settings";
import NotFound from "../pages/NotFound";

const AppRoutes = () => {
   return(
       <BrowserRouter>      
            <Switch>
                <Route component = { Home }  path="/home" exact/>             
                <Route component = { Clients }  path="/clients" exact/>             
                <Route component = { Sells }  path="/sells" exact/>             
                <Route component = { Products }  path="/products" exact/>             
                <Route component = { AbandonadedCarts }  path="/abandonedcarts" exact/>             
                <Route component = { Integrations }  path="/integrations" exact/>             
                <Route component = { Settings }  path="/settings" exact/>             
                <Route path="*" component={ NotFound } exact/>             
            </Switch>
       </BrowserRouter>
   )
}

export default AppRoutes;