import React from "react";
import './style.css';

const PageHeader = () => {
    return(
        <header style={{background: 'black', height: '40px', display: "flex", width: '100%', justifyContent:'space-between'}}>
            <div style={{display: "flex", alignItems: "center", paddingLeft: 16}}>
                <span style={{color: "white"}}>Dashboard</span>
            </div>
            <div style={{alignSelf: 'center', paddingRight: 32}}>
                <a style={{textDecoration: 'none', color: "white"}}>Usuario</a>
            </div>
            {/* <ul>
                <li className="nav-item"><a href="/" className="nav-link">Anakin Skywalker</a></li>
            </ul> */}
        </header>
    )
}

export default PageHeader;