// import config from '@config'
// const endpoint = '/assinaturas'

// const PROXY_URL = config.PROXY_URL

const getTeste = async (user, password) => {
    try {        
        const response = await fetch(
            // `${PROXY_URL + endpoint}/assinantes/login`,
            // `https://sevec-checkout-7f479772ab84.herokuapp.com/conteudos/aws/getTeste`,
            `http://localhost:3004/conteudos/aws/getTeste`,
            {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        )
        return await response.json()
    } catch (error) {
        return error
    }
} 

const getUserById = async (idUser) => {
    try {        
        const response = await fetch(
            `https://sevec-checkout-7f479772ab84.herokuapp.com/conteudos/aws/getUserById/${idUser}`,
            {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        )
        return await response.json()
    } catch (error) {
        return error
    }
} 

export default {
    getTeste,
    getUserById
}
